import { Avatar, Button, Layout, Select, Dropdown } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import logo from '../../assets/img/logo_inclick_new.svg';
import CommonStore from '../../store/CommonStore';
import { SiderTheme } from 'antd/lib/layout/Sider';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import AppRouter from '../../router';
import NavigationBar from './NavigationBar';
import { AdminRoles, Shop, ValidationError } from '../../types';
import api from '../../api/api';
import { Observer } from 'mobx-react';
import {
  Account,
  ArrowDown,
  LogOut,
  Notifications,
  Question,
} from '../../UI/Icons';

const { Sider, Content, Header } = Layout;

const MainLayout = () => {
  const { t } = useTranslation();

  //
  const storeCommon = useContext(CommonStore);

  //
  const history = useHistory();
  const location = useLocation();

  //
  const [, , removeCookie] = useCookies(['token']);

  //
  const [theme, setTheme] = useState<SiderTheme>(
    (localStorage.getItem('theme') || 'dark') as SiderTheme
  );

  //
  const [shops, setShops] = useState<Shop[]>([]);

  //
  const logout = () => {
    storeCommon.setUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('shop');

    removeCookie('token', {
      path: '/',
    });

    history.replace('/login');
  };

  //
  useEffect(() => {
    if (storeCommon.user?.role === AdminRoles.SUPER_ADMIN) {
      (async () => {
        const resp: { data?: Shop[]; error?: ValidationError[] } = await api(
          'shops?per-page=1000000'
        );
        console.log(resp);
        setShops(resp.data || []);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Header className="ic-header">
        <Link to="/">
          <img src={logo} height={44} alt="InClick logo" />
        </Link>
        <div
          style={{
            display: 'flex',
            marginInlineStart: 'auto',
            alignItems: 'center',
          }}
        >
          <Button className="ic-notification__button" type="link">
            <Notifications />
          </Button>
          <Button className="ic-help__button" icon={<Question />} type="link">
            {t('Help & Support')}
          </Button>

          <Dropdown
            trigger={['click']}
            overlay={
              <div className="ic-dropdown__menu">
                <Link to={`/admins/${storeCommon.user?._id}`}>
                  <Button
                    className="ic-dropdown__menu-button"
                    type="link"
                    icon={<Account />}
                    onClick={() => {}}
                  >
                    {t('Edit profile')}
                  </Button>
                </Link>

                <Button
                  className="ic-dropdown__menu-button"
                  type="link"
                  icon={<LogOut />}
                  onClick={logout}
                >
                  {t('Logout')}
                </Button>
              </div>
            }
            placement="bottomRight"
          >
            <div className="ic-dropdown__button">
              <Avatar
                size={32}
                style={{
                  backgroundColor: '#F0F2F5',
                  color: '#4DC0AE',
                  marginInlineEnd: 8,
                }}
              >
                {storeCommon.user?.username[0].toUpperCase()}
              </Avatar>
              <div className="ic-dropdown__button-text">
                <span className="username">{storeCommon.user?.username}</span>
                <br />
                <span className="email">{storeCommon.user?.email}</span>
              </div>

              <ArrowDown />
            </div>
          </Dropdown>
        </div>
      </Header>
      <Layout>
        <Observer>
          {() =>
            storeCommon.user?.role === AdminRoles.SUPER_ADMIN ||
            (!!storeCommon.user?.shop?.settings?.about &&
              !location.pathname.startsWith('/quick')) ? (
              <Sider
                width={256}
                theme={'light'}
                style={{
                  position: 'sticky',
                  top: 0,
                  minHeight: 'calc(100vh - 64px)',
                }}
              >
                {storeCommon.user?.role === AdminRoles.SUPER_ADMIN && (
                  <Select
                    defaultValue={localStorage.getItem('shop') || ''}
                    onChange={(_id) => {
                      if (_id) {
                        localStorage.setItem('shop', _id);
                      } else {
                        localStorage.removeItem('shop');
                      }
                    }}
                    size="large"
                  >
                    <Select.Option value="">
                      {t('Select Shop...')}
                    </Select.Option>
                    {shops.map((shop) => (
                      <Select.Option value={shop._id} key={shop._id}>
                        <img
                          alt=""
                          src={shop.logo?.url}
                          style={{ height: 30, width: 30 }}
                        />{' '}
                        {shop.title}
                      </Select.Option>
                    ))}
                  </Select>
                )}

                <NavigationBar theme={theme} />
              </Sider>
            ) : (
              <></>
            )
          }
        </Observer>
        <Content style={{ minHeight: 'calc(100vh - 64px)' }}>
          <AppRouter />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
