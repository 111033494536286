import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

import i18next from 'i18next';
import {I18nextProvider, initReactI18next} from 'react-i18next';
import he from './translations/he';
import {CookiesProvider} from 'react-cookie';

i18next.use(initReactI18next).init({
  lng: 'he', // window.document.querySelector("html")?.lang || "he",
  resources: {
    he,
  },
  fallbackLng: 'en',
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </I18nextProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
