import {DataNode} from 'antd/lib/tree';
import {Moment} from 'moment';

export type Admin = {
  _id: string;
  username: string;
  email: string;
  status: number;
  role: AdminRoles;
  phone: string;

  // shop_id: string;
  shop: Shop | null;

  plan: AdminPlan | null;

  //////////
  member_dob?: number | Moment;
  member_id_date?: number | Moment;

  /////////
  has_category?: boolean;
  has_product?: boolean;
};

export enum AdminRoles {
  SUPER_ADMIN = 0,
  SHOP_ADMIN = 10,
  SHOP_MANAGER = 100,
}

export enum AdminPlan {
  BASIC = 10,
  START = 100,
  PROFY = 1000,
}

export type ValidationError = {
  field: string;
  message: string;
};

export enum Measurement {
  PTS = 0,
  KG = 1000,
  GR = 100,
}

export type Shop = {
  _id: string;
  branches: Branch[];
  title: string;
  email: string;
  status: number;
  domain: string;
  phones: string[];
  socials: {[key: string]: string};

  title_show: boolean;
  logo_show: boolean;
  logo: TypeFile | null;
  settings: Setting;

  payme_seller_id?: string;

  slug?: string;
  seller_data?: {
    legal_id: string;
    legal_name: string;
    phone: string;
  };
  seller_dashboard_signup_link?: string;
  seostrider_id?: number;
};

export type Branch = {
  _id: string;
  status: number;
  title: string;
  email: string;
  phones: string[];
  seller_id: string;
  address: {
    google_address?: google.maps.GeocoderResult;
  };
  shipment?: string;
  // shop_id: string;
  user: {
    name: string;
    phone: string;
  };
  installments?: number;
  seller_public_key: string | null;
  payme_prod?: boolean;
};

export type Seller = {
  _id: string;

  legal_name: string;
  legal_id: string;
  contact: string;
  phone: string;
  email: string;
  member_name: string;
  member_id: string;
  member_id_date: string;
  member_gender: number;
  member_dob: string;
  member_phone: string;
  country: string;
  city: string;
  street: string;
  house: string;
  bank: number;
  bank_branch: string;
  bank_account_id: string;
  file_id: string;
  file_cert: string;
  file_bank: string;

  //
  shop_id: string;

  //
  payme_files_ok: string;
  payme_seller_create_response: string;
  payme_seller_id: string;
  seller_dashboard_signup_link: string;
};

export type TypeFile = {
  created_at: number;
  extention: string;
  mime_type: string;
  size: number;
  title: string;
  _id: string;
  url: string;
};

export type Product = {
  _id: string;
  title: {[key: string]: string};
  slug: string;
  status: ProductStatus;
  sku?: string;

  short: {[key: string]: string};
  description: {[key: string]: string};

  images: TypeFile[];
  categories: string[];
  options?: string[];
  properties: {property: string; value: string}[];

  price: number;
  price_unit?: Measurement;
  published: number;
  sale_from?: number | null;
  sale_price?: number;

  stock: number;

  subtract: number;
  weight: number;
  dimensions: {l: number; w: number; h: number};

  variants?: Variant[];

  variants_images: {value: string; images: TypeFile[]}[];

  brand: Brand | null;
  brand_id: string | null;

  comax_data?: string;

  promotion: Promotion | null;
};

export type Option = {
  _id: string;
  title: {[key: string]: string};
  inputType: string;
  images: boolean;
  status: number;
  values?: OptionValue[];
};

export type OptionValue = {
  _id: string;
  option_id: string;
  title: {[key: string]: string};
  sort: string;
  status: number;
};

export type Property = {
  _id: string;
  title: {[key: string]: string};
  type: 0 | 1;
  postfix: string;
  prefix: string;
  status: number;
  values?: PropertyValue[];
};

export type PropertyValue = {
  _id: string;
  property_id: string;
  title: {[key: string]: string};
  sort: string;
  status: number;
};

export interface Category extends DataNode {
  _id: string;
  title: {[key: string]: string};
  description: string;
  children: Category[];
  parent_id: string | null;
  status: number;
  productsCount: number;
  values?: OptionValue[];
  header_image: TypeFile | null;
  promotion_image: TypeFile | null;
  menu_icon: TypeFile | null;
  promotion: boolean;
}

export type Template = {
  _id: string;
  title: string;
  description: string;
  schemas: string[][];
  settingConfig?: {
    label: string;
    name: string;
    type: string;
    list: string[];
    aboutTop: string;
    benefitsTop: string;
    aboutHeight: string;
    benefitsHeight: string;
    mainBannerHeight: string;
    categoryes: string[];
  };
  status: number;
};

export type Setting = {
  _id: string;
  shop_id: string;
  template_id: string;
  template?: Template;
  schema: number;
  template_settings: any;
  shop?: Shop;
  languages: string[];

  benefits: BenefitsType;
  about: AboutType;
  // gett_delivery?: GettShipment;
  yango_delivery?: YangoShipment;
};

export type BenefitsType = {
  active: boolean;
  title: {[key: string]: string};
  text: {[key: string]: string};
  benefits: {
    image: TypeFile | null;
    title: {[key: string]: string};
    text: {[key: string]: string};
  }[];
};

export type AboutType = {
  active: boolean;
  title: {[key: string]: string};
  text: {[key: string]: string};
  image: TypeFile | null;
  with_button: boolean;
  button_text: {[key: string]: string};
  button_url: string;
};

export type UserGroup = {
  _id: string;
  title: {
    he: string;
    ru: string;
  };
  description: string;
  status: number;
  discont: number;
  agent_number: number;
  with_cashless: boolean;
};

export type User = {
  avatar: string | null;
  created_at: number;
  email: string;
  f_id: string | null;
  g_id: string | null;
  first_name: string;
  last_name: string;
  status: number;
  _id: string;
  phone: string;
  group_id?: string;
  group?: UserGroup;
};

export type Brand = {
  _id: string;
  title: {[key: string]: string};
  status: number;
  promotion: boolean;
  logo: TypeFile | null;
};

export type Testimonial = {
  _id: string;
  image: TypeFile | null;
  username: string;
  company: string;
  text: string;
  status: number;
};

export type Banner = {
  _id: string;
  title: {[key: string]: string};
  text: {[key: string]: string};
  status: number;
  type: string;
  button_text: {[key: string]: string};
  with_button: boolean;
  image: TypeFile | null;
  invert_image?: boolean;
  button_url: string;
  text_color?: string;
  overlay?: boolean;
  overlay_color?: string;
  overlay_opacity?: number;
};

export type Order = {
  _id: string;
  branch_id?: string;
  number: string;
  status: number;
  user_id: string | null;
  account: User | null;
  address: {
    flat: string;
    building: string;
    city: string;
    country: string;
    street: string;
    zip: string;
  };
  products: OrderProduct[];
  total: number;
  currency: string;
  user: {
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
  };
  created_at: number;
  payment: PaymentInfo;

  shipment_id?: string;
  shipment: Shipment | null;
  shipment_price?: number;

  history: OrderHistory[];

  returns?: OrderReturn[];

  gett_estimation?: {
    quote_id: string;
    currency: string;
    currency_code: string;
    price: {
      include_tax: boolean;
      value: string;
    };
    tech_fee: {
      applicable: boolean;
      value: number;
    };
  };

  yango_estimation?: {
    price: string;
  };

  getpackage_estimation?: {
    price: string;
  };

  invoice?: string;

  shipment_estimation?: {
    price: string;
  };

  credits?: number;

  comment?: string;
};

export type OrderProduct = {
  discount: number;
  price: number;
  product_id: string;
  quantity: number;
  sale_price: number;
  total: number;
  variant?: {
    option: string;
    value: string;
  }[];
  promotion_id?: string;
  product: OrderProductProduct;
  status?: boolean;
};

export type OrderProductProduct = {
  _id: string;
  title: {[key: string]: string};
  sku: string | null;
  slug: string;
  status: number;
  price: number;
  sale_price: number | null;
  sale_from: number | null;
  price_min: number;
  price_max: number;
  price_unit?: Measurement;

  short: string;
  description: string;

  images: TypeFile[];
  categories: string[];

  options: ProductOption[];

  properties: {
    property: Property;
    value: PropertyValue;
  }[];
  variants: Variant[];
  variants_images: {
    value: string;
    images: TypeFile[];
  }[];

  stock: number;

  real_price: number;

  promotion: Promotion | null;
  main_category?: Category;
  comax_data?: string;
};

export type OrderReturn = {
  _id: string;
  created_at: number;
  currency: string;
  order_id: string;
  products_data: OrderProduct[];
  status: number;
  total: number;
  total_default: number;
  updated_at: number;
  user_id: string;
  history: OrderHistory[];
  products?: OrderProduct[];
  order?: Order;
  user?: User;
};

export type ProductOption = {
  _id: string;
  title: string;
  inputType: string;
  images: boolean;
  values?: OptionValue[];
};

export type Variant = {
  values: {option: string; value: string}[];
  sku?: string;
  price: number;
  sale_price: number | null;
  stock: number;
  sale_from: number | null;
};

export type PaymentInfo = {
  buyer_card_exp: string;
  buyer_card_mask: string;
  buyer_email: string;
  buyer_name: string;
  buyer_phone: string;
  payme_transaction_card_brand: string;
  sale_paid_date: string;
  price: number;
};

export type Shipment = {
  _id: string;
  title: string;
  description: string;
  tooltip: string;
  status: number;
  price: number;
  free_from?: number;
  duration: string;
  custom_sms?: {he: string; en: string};
};

export type YangoShipment = {
  _id: string;
  title: string;
  free_from?: number;
  tooltip: string;
  status: number;
  user: {
    name: string;
    phone: string;
  };
  address: {
    city: '';
    street: '';
    building: '';
    location: {
      lat: 0;
      lng: 0;
    };
    google_address?: {
      place_id: string;
      formatted_address: string;
    };
  };
};

// export type GettShipment = {
//   _id: string;
//   title: string;
//   free_from?: number;
//   tooltip: string;
//   status: number;
//   user: {
//     name: string;
//     phone: string;
//   };
//   address: {
//     city: "";
//     street: "";
//     building: "";
//     location: {
//       lat: 0;
//       lng: 0;
//     };
//     google_address?: {
//       place_id: string;
//       formatted_address: string;
//     };
//   };
// };

export type OrderHistory = {
  created_at: number;
  notify: boolean;
  status: number;
  text: string;
};

export type Review = {
  _id: string;
  user_id: string;
  user: User;
  created_at: number;
  stars: number;
  text: string;
  parent_id?: string;
  likes: number;
  product_id: string;
  parent: Review | null;
  status: number;
};

export type BotMessageType = {
  text: string;
  image?: string;
  video?: string;
};

export type TermType = {
  _id: string;
  title: string;
  content: string;
  date: number;
  type: TermsType;
  status: number;
};

export enum TermsType {
  PRIVACY = 10,
  TERMS = 100,
  PAYMENTS = 200,
  RETURNS = 300,
  SHIPMENT = 400,
}

export enum PromotionType {
  BY_X_FOR_Y = 1,
  ITEM_FREE = 6,
}

export type Promotion = {
  _id: string;
  title: {[key: string]: string};
  type: PromotionType;
  product_free?: Product;
  quantity: number;
  min: number;
  products: string[];
  price?: number;
};

export enum ProductStatus {
  DELETED = 0,
  NOT_ACTIVE = 9,
  ACTIVE = 10,
}

export enum ShipmentStatus {
  DELETED = 0,
  NOT_ACTIVE = 9,
  ACTIVE = 10,
}
