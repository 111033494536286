import Icon from '@ant-design/icons';

const eyeClose = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.69 6.52505L2.01 3.84505L3.42 2.42505L21.15 20.165L19.74 21.575L16.32 18.155C14.98 18.685 13.52 18.975 12 18.975C7 18.975 2.73 15.865 1 11.475C1.77 9.50505 3.06 7.80505 4.69 6.52505ZM12 5.97505C15.79 5.97505 19.17 8.10505 20.82 11.475C20.23 12.695 19.4 13.745 18.41 14.595L19.82 16.005C21.21 14.775 22.31 13.235 23 11.475C21.27 7.08505 17 3.97505 12 3.97505C10.73 3.97505 9.51 4.17505 8.36 4.54505L10.01 6.19505C10.66 6.06505 11.32 5.97505 12 5.97505ZM10.93 7.11505L13 9.18505C13.57 9.43505 14.03 9.89505 14.28 10.465L16.35 12.535C16.43 12.195 16.49 11.835 16.49 11.465C16.5 8.98505 14.48 6.97505 12 6.97505C11.63 6.97505 11.28 7.02505 10.93 7.11505ZM9.51 11.345L12.12 13.955C12.08 13.965 12.04 13.975 12 13.975C10.62 13.975 9.5 12.855 9.5 11.475C9.5 11.4501 9.5025 11.4301 9.505 11.4101L9.505 11.41L9.505 11.41C9.5075 11.39 9.51 11.37 9.51 11.345ZM7.86 9.69505L6.11 7.94505C4.9 8.86505 3.88 10.045 3.18 11.475C4.83 14.845 8.21 16.975 12 16.975C12.95 16.975 13.87 16.835 14.75 16.595L13.77 15.615C13.23 15.845 12.63 15.975 12 15.975C9.52 15.975 7.5 13.955 7.5 11.475C7.5 10.845 7.63 10.245 7.86 9.69505Z'
      fill='#18214D'
    />
  </svg>
);

const eyeOpen = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <g id='visibility_24px'>
      <path
        id='icon/action/visibility_24px'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 12C2.73 7.61 7 4.5 12 4.5C17 4.5 21.27 7.61 23 12C21.27 16.39 17 19.5 12 19.5C7 19.5 2.73 16.39 1 12ZM20.82 12C19.17 8.63 15.79 6.5 12 6.5C8.21 6.5 4.83 8.63 3.18 12C4.83 15.37 8.21 17.5 12 17.5C15.79 17.5 19.17 15.37 20.82 12ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM7.5 12C7.5 9.52 9.52 7.5 12 7.5C14.48 7.5 16.5 9.52 16.5 12C16.5 14.48 14.48 16.5 12 16.5C9.52 16.5 7.5 14.48 7.5 12Z'
        fill='#18214D'
      />
    </g>
  </svg>
);

const notifications = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18 15.75V10.75C18 7.68 16.37 5.11 13.5 4.43V3.75C13.5 2.92 12.83 2.25 12 2.25C11.17 2.25 10.5 2.92 10.5 3.75V4.43C7.64 5.11 6 7.67 6 10.75V15.75L4 17.75V18.75H20V17.75L18 15.75ZM12 21.75C13.1 21.75 14 20.85 14 19.75H10C10 20.85 10.9 21.75 12 21.75ZM8 16.75H16V10.75C16 8.27 14.49 6.25 12 6.25C9.51 6.25 8 8.27 8 10.75V16.75Z'
      fill='#18214D'
    />
  </svg>
);

const question = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM13 16V18H11V16H13ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM8 10C8 7.79 9.79 6 12 6C14.21 6 16 7.79 16 10C16 11.2829 15.21 11.9733 14.4408 12.6455C13.711 13.2833 13 13.9046 13 15H11C11 13.1787 11.9421 12.4566 12.7704 11.8217C13.4202 11.3236 14 10.8792 14 10C14 8.9 13.1 8 12 8C10.9 8 10 8.9 10 10H8Z'
      fill='#18214D'
    />
  </svg>
);

const computer = () => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <g id='laptop_mac_24px'>
      <path
        id='icon/hardware/laptop_mac_24px'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.33333 24.6667C3.86666 24.6667 2.68 23.4667 2.68 22.0001L2.66667 7.33341C2.66667 5.86675 3.86666 4.66675 5.33333 4.66675H26.6667C28.1333 4.66675 29.3333 5.86675 29.3333 7.33341V22.0001C29.3333 23.4667 28.1333 24.6667 26.6667 24.6667H32C32 26.1334 30.8 27.3334 29.3333 27.3334H2.66667C1.2 27.3334 0 26.1334 0 24.6667H5.33333ZM26.6667 7.33342H5.33333V22.0001H26.6667V7.33342ZM17.3333 24.6667C17.3333 25.4001 16.7333 26.0001 16 26.0001C15.2667 26.0001 14.6667 25.4001 14.6667 24.6667C14.6667 23.9334 15.2667 23.3334 16 23.3334C16.7333 23.3334 17.3333 23.9334 17.3333 24.6667Z'
        fill='#18214D'
      />
    </g>
  </svg>
);

const account = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <g id='account_circle_24px'>
      <path
        id='icon/action/account_circle_24px'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM7.07 18.28C7.5 17.38 10.12 16.5 12 16.5C13.88 16.5 16.51 17.38 16.93 18.28C15.57 19.36 13.86 20 12 20C10.14 20 8.43 19.36 7.07 18.28ZM12 14.5C13.46 14.5 16.93 15.09 18.36 16.83C19.38 15.49 20 13.82 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12C4 13.82 4.62 15.49 5.64 16.83C7.07 15.09 10.54 14.5 12 14.5ZM12 6C10.06 6 8.5 7.56 8.5 9.5C8.5 11.44 10.06 13 12 13C13.94 13 15.5 11.44 15.5 9.5C15.5 7.56 13.94 6 12 6ZM10.5 9.5C10.5 10.33 11.17 11 12 11C12.83 11 13.5 10.33 13.5 9.5C13.5 8.67 12.83 8 12 8C11.17 8 10.5 8.67 10.5 9.5Z'
        fill='#18214D'
      />
    </g>
  </svg>
);

const logOut = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V16C3 15.45 3.45 15 4 15C4.55 15 5 15.45 5 16V18C5 18.55 5.45 19 6 19H18C18.55 19 19 18.55 19 18V6C19 5.45 18.55 5 18 5H6C5.45 5 5 5.45 5 6V8C5 8.55 4.55 9 4 9C3.45 9 3 8.55 3 8V5C3 3.9 3.89 3 5 3ZM15.79 12.7L12.2 16.29C11.81 16.68 11.18 16.68 10.79 16.29C10.41 15.91 10.4 15.27 10.79 14.88L12.67 13H4C3.45 13 3 12.55 3 12C3 11.45 3.45 11 4 11H12.67L10.79 9.11C10.4 8.72 10.4 8.09 10.79 7.7C10.9768 7.51275 11.2305 7.40751 11.495 7.40751C11.7595 7.40751 12.0132 7.51275 12.2 7.7L15.79 11.29C16.18 11.68 16.18 12.31 15.79 12.7Z'
      fill='#18214D'
    />
  </svg>
);

const arrowDown = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12 15.7051L18 9.70508L16.59 8.29508L12 12.8751L7.41 8.29508L6 9.70508L12 15.7051Z'
      fill='#18214D'
    />
  </svg>
);

const arrowLeft = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.835 3.8701L16.055 2.1001L6.16504 12.0001L16.065 21.9001L17.835 20.1301L9.70504 12.0001L17.835 3.8701Z'
      fill='#18214D'
    />
  </svg>
);

const arrowBack = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z'
      fill='#18214D'
    />
  </svg>
);

const brush = () => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.9588 3.29C19.1588 3.1 19.4088 3 19.6688 3C19.9188 3 20.1688 3.09 20.3687 3.29L21.7087 4.63C22.0988 5.02 22.0988 5.65 21.7087 6.04L12.7488 15L9.99875 12.25L18.9588 3.29ZM4.99875 17C4.99875 15.34 6.33875 14 7.99875 14C9.65875 14 10.9988 15.34 10.9988 17C10.9988 19.21 9.20875 21 6.99875 21C5.48875 21 3.91875 20.22 2.99875 19C3.83875 19 4.99875 18.31 4.99875 17Z'
      fill='#18214D'
    />
  </svg>
);

const arrowForward = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z'
      fill='#18214D'
    />
  </svg>
);

const phone = () => (
  <svg
    width='28'
    height='28'
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <g id='phone_iphone_24px'>
      <path
        id='icon/hardware/phone_iphone_24px'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.33331 1.16675H18.6666C20.2766 1.16675 21.5833 2.47341 21.5833 4.08342V23.9167C21.5833 25.5268 20.2766 26.8334 18.6666 26.8334H9.33331C7.72331 26.8334 6.41665 25.5268 6.41665 23.9167V4.08342C6.41665 2.47341 7.72331 1.16675 9.33331 1.16675ZM14 25.6667C14.9683 25.6667 15.75 24.8851 15.75 23.9167C15.75 22.9484 14.9683 22.1667 14 22.1667C13.0316 22.1667 12.25 22.9484 12.25 23.9167C12.25 24.8851 13.0316 25.6667 14 25.6667ZM19.25 21.0001H8.74998V4.66675H19.25V21.0001Z'
        fill='#18214D'
      />
    </g>
  </svg>
);

const tablet = () => (
  <svg
    width='28'
    height='28'
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <g id='tablet_mac_24px'>
      <path
        id='icon/hardware/tablet_mac_24px'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.22221 0.666748H21.7778C23.3111 0.666748 24.5555 1.91119 24.5555 3.44453V24.5556C24.5555 26.089 23.3111 27.3334 21.7778 27.3334H6.22221C4.68888 27.3334 3.44443 26.089 3.44443 24.5556V3.44453C3.44443 1.91119 4.68888 0.666748 6.22221 0.666748ZM14 26.2223C14.9222 26.2223 15.6667 25.4779 15.6667 24.5556C15.6667 23.6334 14.9222 22.889 14 22.889C13.0778 22.889 12.3333 23.6334 12.3333 24.5556C12.3333 25.4779 13.0778 26.2223 14 26.2223ZM22.3333 21.7779H5.66665V4.00008H22.3333V21.7779Z'
        fill='#18214D'
      />
    </g>
  </svg>
);

//
const EyeClose = (props: any) => <Icon component={eyeClose} {...props} />;
const EyeOpen = (props: any) => <Icon component={eyeOpen} {...props} />;
const Question = (props: any) => <Icon component={question} {...props} />;
const Notifications = (props: any) => (
  <Icon component={notifications} {...props} />
);

const Computer = (props: any) => <Icon component={computer} {...props} />;
const Phone = (props: any) => <Icon component={phone} {...props} />;
const Tablet = (props: any) => <Icon component={tablet} {...props} />;
const Account = (props: any) => <Icon component={account} {...props} />;
const LogOut = (props: any) => <Icon component={logOut} {...props} />;
const ArrowDown = (props: any) => <Icon component={arrowDown} {...props} />;
const ArrowLeft = (props: any) => <Icon component={arrowLeft} {...props} />;
const ArrowBack = (props: any) => <Icon component={arrowBack} {...props} />;
const ArrowForward = (props: any) => (
  <Icon component={arrowForward} {...props} />
);
const Brush = (props: any) => <Icon component={brush} {...props} />;

//
export {
  Account,
  ArrowBack,
  ArrowDown,
  ArrowForward,
  ArrowLeft,
  Brush,
  Computer,
  EyeClose,
  EyeOpen,
  LogOut,
  Notifications,
  Phone,
  Question,
  Tablet,
};
