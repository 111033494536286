import { lazy, Suspense, useContext, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import logo from '../assets/img/logo_inclick_new.svg';
import { useTranslation } from 'react-i18next';
import CommonStore from '../store/CommonStore';
import { autorun } from 'mobx';
import { AdminRoles } from '../types';

const Login = lazy(() => import('../pages/Login'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const SignUp = lazy(() => import('../pages/SignUp'));
const CreateShop = lazy(() => import('../pages/CreateShop'));

// const SignUp = lazy(() => import("../pages/SignUp"));
// const Invite = lazy(() => import("../pages/Invite"));

const GuestRouter = () => {
  //
  const storeCommon = useContext(CommonStore);

  //
  const history = useHistory();

  //
  const { t } = useTranslation();

  //
  useEffect(() => {
    autorun(() => {
      if (
        storeCommon.user &&
        !storeCommon.user.shop &&
        storeCommon.user.role !== AdminRoles.SUPER_ADMIN
      ) {
        history.replace('/create');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={null}>
      <Layout
        style={{
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ minWidth: 560, maxWidth: 560 }}>
          <h1>
            <Link
              to="/"
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 35,
                alignItems: 'center',
                fontWeight: 400,
                fontSize: 20,
              }}
            >
              <img src={logo} alt="InClick Logo 2023" />
              {t('Admin')}
            </Link>
          </h1>
          <Switch>
            <Route path="/login" exact render={() => <Login />} />
            <Route
              path="/forgot-password"
              exact
              render={() => <ForgotPassword />}
            />
            <Route path="/sign-up" exact render={() => <SignUp />} />
            <Route path="/create" exact render={() => <CreateShop />} />
            <Route render={() => <Login />} />;
          </Switch>
        </div>
      </Layout>
    </Suspense>
  );
};

export default GuestRouter;
