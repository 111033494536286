import { action, observable, makeObservable } from "mobx";
import { createContext } from "react";
// import api from "../api/api";
import { Admin, Shop } from "../types";

class CommonStore {
  user: Admin | null = null;
  shop: Shop | null = null;

  constructor(user: Admin | null, shop: Shop | null) {
    makeObservable(this, {
      user: observable,
      shop: observable,

      setUser: action,
      setShop: action,
    });

    this.user = user;
    this.shop = shop;

    // this.setInitValues();
  }

  // setInitValues = async () => {
  //   const token = localStorage.getItem("token");
  //   if (!token) return;

  //   //
  //   const user = await api("admins/me");
  //   if (user?.id) {
  //     this.user = user;
  //   }
  // };

  setUser = (user: Admin | null) => {
    this.user = user;
  };

  setShop = (shop: Shop | null) => {
    this.shop = shop;
  };
}

export default createContext(new CommonStore(null, null));
