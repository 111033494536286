import { Menu, MenuProps } from 'antd';
import React, { Children, ReactNode, useContext } from 'react';
import {
  UserOutlined,
  DashboardOutlined,
  TeamOutlined,
  ShopOutlined,
  ShoppingOutlined,
  BranchesOutlined,
  DeploymentUnitOutlined,
  AppstoreAddOutlined,
  BookOutlined,
  SettingOutlined,
  TagOutlined,
  CommentOutlined,
  ContainerOutlined,
  ShoppingCartOutlined,
  ReadOutlined,
  GroupOutlined,
  HomeOutlined,
  SafetyCertificateOutlined,
  RetweetOutlined,
  StockOutlined,
  CarOutlined,
  DollarOutlined,
  ClusterOutlined,
  StarOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import CommonStore from '../../store/CommonStore';
import { Link, useLocation } from 'react-router-dom';
import { AdminRoles } from '../../types';

type MenuType = {
  key: string;
  label: string | ReactNode;
  icon: ReactNode;
  items?: { key: string; label: string | ReactNode; icon: ReactNode }[];
}[];

const { SubMenu, Item } = Menu;

const NavigationBar: React.FC<{ theme: 'dark' | 'light' }> = ({ theme }) => {
  //
  const { t } = useTranslation();

  //
  const storeCommon = useContext(CommonStore);

  //
  const location = useLocation();

  //
  // const [moderationQnt, setModerationQnt] = useState(0);

  // useEffect(() => {
  //   (async () => {
  //     if (storeCommon.user?.role !== 100) return;

  //     const respSynagogues = await api(
  //       "synagogues?per-page=100&page=1&sort=id&filter[and][0][or][][synagogue.status]=10"
  //     );
  //     setModerationQnt(respSynagogues.total);
  //   })();
  // }, [location.pathname]);

  const menu: { [key: number]: MenuType } = {
    [AdminRoles.SUPER_ADMIN]: [
      {
        key: '',
        label: t('Dasboard'),
        icon: <DashboardOutlined />,
      },
      {
        key: 'shops',
        label: t('Shops'),
        icon: <ShopOutlined />,
      },
      {
        key: 'catalog',
        label: t('Catalog'),
        icon: <ShoppingOutlined />,
        items: [
          {
            key: 'products',
            label: t('Products'),
            icon: <ShoppingOutlined />,
          },
          {
            key: 'categories',
            label: t('Categories'),
            icon: <BranchesOutlined />,
          },
          {
            key: 'brands',
            label: t('Brands'),
            icon: <TagOutlined />,
          },
          {
            key: 'options',
            label: t('Options'),
            icon: <DeploymentUnitOutlined />,
          },
          {
            key: 'properties',
            label: t('Properties'),
            icon: <AppstoreAddOutlined />,
          },
        ],
      },
      {
        key: 'orders',
        label: t('Orders'),
        icon: <ShoppingCartOutlined />,
        items: [
          {
            key: 'orders',
            label: t('Orders'),
            icon: <ShoppingCartOutlined />,
          },
          {
            key: 'returns',
            label: t('Returns'),
            icon: <RetweetOutlined />,
          },
        ],
      },
      {
        key: 'users',
        label: t('Users'),
        icon: <TeamOutlined />,
      },
      {
        key: 'content',
        label: t('Contens'),
        icon: <TeamOutlined />,
        items: [
          {
            key: 'banners',
            label: t('Banners'),
            icon: <ContainerOutlined />,
          },
          {
            key: 'testimonials',
            label: t('Testimonials'),
            icon: <CommentOutlined />,
          },
        ],
      },
      {
        key: 'shop',
        label: t('Settings'),
        icon: <SettingOutlined />,
        items: [
          {
            key: 'settings',
            label: t('Shop'),
            icon: <ShopOutlined />,
          },
          {
            key: 'admins',
            label: t('Admins'),
            icon: <UserOutlined />,
          },
        ],
      },
      {
        key: 'templates',
        label: t('Templates'),
        icon: <BookOutlined />,
      },
    ],
    [AdminRoles.SHOP_ADMIN]: [
      {
        key: '',
        label: t('Dasboard'),
        icon: <DashboardOutlined />,
      },
      {
        key: 'catalog',
        label: t('Products management'),
        icon: <ShoppingOutlined />,
        items: [
          {
            key: 'products',
            label: t('Products'),
            icon: <ShoppingOutlined />,
          },
          {
            key: 'categories',
            label: t('Categories'),
            icon: <BranchesOutlined />,
          },
          {
            key: 'brands',
            label: t('Brands'),
            icon: <TagOutlined />,
          },
          {
            key: 'options',
            label: t('Options'),
            icon: <DeploymentUnitOutlined />,
          },
          {
            key: 'properties',
            label: t('Characteristics'),
            icon: <AppstoreAddOutlined />,
          },
        ],
      },
      {
        key: 'orders-menu',
        label: t('Orders'),
        icon: <ShoppingCartOutlined />,
        items: [
          {
            key: 'orders',
            label: t('Orders'),
            icon: <ShoppingCartOutlined />,
          },
          {
            key: 'returns',
            label: t('Returns'),
            icon: <RetweetOutlined />,
          },
        ],
      },
      {
        key: 'users-list',
        label: t('Users'),
        icon: <TeamOutlined />,
        items: [
          { key: 'users', label: t('Users'), icon: <UserOutlined /> },
          {
            key: 'user-groups',
            label: t('User Groups'),
            icon: <UsergroupAddOutlined />,
          },
          { key: 'reviews', label: t('Reviews'), icon: <ReadOutlined /> },
        ],
      },
      {
        key: 'shop',
        label: t('Shop settings'),
        icon: <SettingOutlined />,
        items: [
          {
            key: 'settings',
            label: t('Common settings'),
            icon: <ShopOutlined />,
          },
          {
            key: 'banners',
            label: t('Banners'),
            icon: <ContainerOutlined />,
          },
          {
            key: 'benefits',
            label: t('Shop benefits'),
            icon: <DeploymentUnitOutlined />,
          },
          {
            key: 'about',
            label: t('About shop'),
            icon: <HomeOutlined />,
          },
          {
            key: 'terms',
            label: t('Terms & Privacy'),
            icon: <SafetyCertificateOutlined />,
          },
          {
            key: 'shipments',
            label: t('Shipments'),
            icon: <CarOutlined />,
          },
          {
            key: 'testimonials',
            label: t('Testimonials'),
            icon: <CommentOutlined />,
          },
          // {
          //   key: "admins",
          //   label: t("Admins"),
          //   icon: <UserOutlined />,
          // },
          {
            key: 'templates',
            label: t('Change template'),
            icon: <GroupOutlined />,
          },
        ],
      },
      {
        key: 'payments',
        label: t('Payments'),
        icon: <DollarOutlined />,
      },
      // {
      //   key: "seostrider",
      //   label: t("Effectivity & Promotion"),
      //   icon: <StockOutlined />,
      // },
      // {
      //   key: "legal",
      //   label: t("Business management"),
      //   icon: <ControlOutlined />,
      // },
      {
        key: 'administration',
        label: t('Administration'),
        icon: <ClusterOutlined />,
        items: [
          {
            key: 'branches',
            label: t('Shops'),
            icon: <StarOutlined />,
          },
          {
            key: 'admins',
            label: t('Admins'),
            icon: <UserOutlined />,
          },
        ],
      },
    ],
    [AdminRoles.SHOP_MANAGER]: [
      {
        key: '',
        label: t('Dasboard'),
        icon: <DashboardOutlined />,
      },
      {
        key: 'catalog',
        label: t('Products management'),
        icon: <ShoppingOutlined />,
        items: [
          {
            key: 'products',
            label: t('Products'),
            icon: <ShoppingOutlined />,
          },
          {
            key: 'categories',
            label: t('Categories'),
            icon: <BranchesOutlined />,
          },
          {
            key: 'brands',
            label: t('Brands'),
            icon: <TagOutlined />,
          },
          {
            key: 'options',
            label: t('Options'),
            icon: <DeploymentUnitOutlined />,
          },
          {
            key: 'properties',
            label: t('Characteristics'),
            icon: <AppstoreAddOutlined />,
          },
        ],
      },
      {
        key: 'orders-menu',
        label: t('Orders'),
        icon: <ShoppingCartOutlined />,
        items: [
          {
            key: 'orders',
            label: t('Orders'),
            icon: <ShoppingCartOutlined />,
          },
          {
            key: 'returns',
            label: t('Returns'),
            icon: <RetweetOutlined />,
          },
        ],
      },
      {
        key: 'users-list',
        label: t('Users'),
        icon: <TeamOutlined />,
        items: [
          { key: 'users', label: t('Users'), icon: <UserOutlined /> },
          {
            key: 'user-groups',
            label: t('User Groups'),
            icon: <UsergroupAddOutlined />,
          },
          { key: 'reviews', label: t('Reviews'), icon: <ReadOutlined /> },
        ],
      },
    ],
  };

  if (storeCommon.user?.shop?.seostrider_id) {
    menu[AdminRoles.SHOP_ADMIN].push({
      key: 'seostrider',
      label: t('Effectivity & Promotion'),
      icon: <StockOutlined />,
    });
  }

  const activeRoute = location.pathname.split('/')[1];

  let openedKey = '';
  if (storeCommon.user) {
    menu[storeCommon.user.role].forEach((item) => {
      const search = item.items?.find((sub) => {
        return sub.key.split('/')[0] === activeRoute;
      });
      if (search) {
        openedKey = item.key;
        return;
      }
    });
  }

  return (
    <Menu
      // onClick={this.handleClick}
      id={`menu-${storeCommon.user?._id}`}
      style={{ width: 256 }}
      className="ic-menu"
      mode="inline"
      theme={'light'}
      defaultOpenKeys={[openedKey]}
      defaultSelectedKeys={[activeRoute]}
      key={`menu-${activeRoute}`}
      disabled={
        storeCommon.user?.role !== AdminRoles.SUPER_ADMIN &&
        storeCommon.user?.shop?.status !== 10
      }
    >
      {storeCommon.user &&
        menu[storeCommon.user.role].map((item, index) => {
          return (
            <React.Fragment key={index}>
              {!item.items && (
                <Item icon={item.icon} key={item.key}>
                  {item.key === 'payments' ? (
                    <a
                      target={'_blank'}
                      href={
                        storeCommon.user?.shop?.seller_dashboard_signup_link ||
                        'https://bogorad.paymeservice.com'
                      }
                      rel="noreferrer"
                    >
                      {item.label}
                    </a>
                  ) : (
                    <Link to={`/${item.key}`}>{item.label}</Link>
                  )}
                </Item>
              )}
              {item.items && (
                <SubMenu
                  className="ic-menu__sub"
                  key={item.key}
                  icon={item.icon}
                  title={item.label}
                >
                  {item.items.map((sub) => {
                    return (
                      <Item icon={sub.icon} key={sub.key}>
                        <Link
                          to={`/${sub.key}`}
                          // style={{color: theme === 'light' ? '#18214d' : ''}}
                        >
                          {sub.label}
                        </Link>
                      </Item>
                    );
                  })}
                </SubMenu>
              )}
            </React.Fragment>
          );
        })}
    </Menu>
  );
};

export default NavigationBar;
