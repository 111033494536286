const he = {
  translation: {
    'About (Shop)': 'אודות (החנות)',
    'About title': 'כותרת',
    'About store': 'אודות החנות',
    Account: 'חשבון',
    Action: 'פעולה',
    Active: 'פעיל',
    'Add banner': 'להוסיף באנר',
    'Add product': 'הוספת מוצרים',
    'Add button': 'הוספת כפתור',
    'Attach domain': 'לחבר דומיין',
    'Create new banner': 'לעדכן באנר',
    'Create new brand': 'ליצור מותג חדש',
    'Create new Brand': 'ליצור מותג חדש',
    'Add field': 'להוסיף / לעדכן',
    'Add an infographic section': 'להוסיף  קטע אינפוגרפיקה',
    'Create main category': 'להוסיף קטגוריה ראשית',
    'Create new option': 'להוסיף אופציה',
    'Create new Option': 'להוסיף מאפיין חדש',
    'Create new product': 'להוסיף מוצר',
    'Create new characteristic': 'להוסיף  תכונה',
    'Create new Propery': 'להוסיף  תכונה',
    'Add setting': 'להוסיף הגדרה',
    'Add shipment': 'הוספת שיטת משלוח',
    'Add shipping method': 'להוסיף שיטת משלוח',
    'Add shop': 'להוסיף חנות',
    'Add subcategory to': 'להוסיף קטגוריה משנית ל -',
    'Add testimonial': 'להוסיף המלצה',
    'Add user': 'להוסיף משתמש',
    'Add Value': 'הוספת ערך',
    'Additonal images': 'יש תמונה',
    Address: 'כתובת',
    Admin: 'ממשק ניהול',
    Admins: 'מנהלי מערכת',
    'Advanced settings': 'הגדרות החנות',
    'Are you sure to delete this category?': 'למחוק את הקטגוריה?',
    'Are you sure?': 'בטוח?',
    'Avalible from date': 'זמין מתאריך',
    Back: 'חזור',
    'Background overlay': 'הכהיה של תמונת רקע',
    Bank: 'שם הבנק',
    'Bank Account Number': 'מספר חשבון',
    'Bank Branch': 'מספר סניף',
    'Bank info': 'פרטי חשבון בנק של העסק',
    Banner: 'באנר',
    'Banner picture': 'תמונת רקע',
    Banners: 'באנרים',
    Benefit: 'קטע ינפוגרפיקה',
    Benefits: 'יתרונות',
    'Benefits title': 'כותרת',
    '"Benefits" information block allows you to advertise on the site the benefits of the store or the products you sell. A block includes a title, a descriptive text and three infographic sections consisting of an icon and a short description. Katie Infographics can help you illustrate the benefits you mentioned in the title and text, as shown in the example.':
      'בלוק מידע ״יתרונות״ מאפשר לכם לפרסם באתר את יתרונות של החנות או של המוצרים שאתם מוכרים. בלוק כולל כותרת, טקסט תיאור ושלושה קטעי אינפוגרפיקה המורכבים מייקון ותיאור קצר. קטי אינפוגרפיקה יכולים לעזור לכם להמחיש יתרונות שציינתם בכותרת וטקסט, כפי שמופיע בדוגמא.',
    Brand: 'מותג',
    Brands: 'מותגים',
    'Button text': 'טקסט על הכפתור',
    'Button URL': 'קישור שיפתח אחרי לחיצה על הכפתור',
    Buyer: 'קונה',
    Cancel: 'לבטל',
    Cart: 'עגלת קניות',
    Catalog: 'קטלוג',
    Categories: 'קטגוריות',
    'Category page H1 tag': 'תאג H1 של עמוד הקטגוריה',
    'Category page image': 'תמונה ראשית בעמוד קטגוריה',
    'Category page SEO description': 'תיאור SEO  של עמוד קטגוריה',
    'Category title': 'כותרת של הקטגוריה',
    'Change password': 'שינוי סיסמה',
    'Check verification code': 'לבדוק קוד אימות',
    'Choose a name for the shipping method. This name will help you in the future to understand what the method is about without opening details':
      'תבחרו שם לשיטת משלוח. השם הזה יעזור לכם בעתיד להבין על מה השיטה בלי לפתוח פרטים',
    'Choose tariff plan': 'בחירת מסלול שימוש ושירותים נוספים',
    'Choose template': 'בחירת תבנית',
    'Сhoose a template for your store': 'נא לבחור תבנית לעיצוב החנות',
    City: 'עיר',
    'Click or drag file to this area to upload':
      'כדי להעלות קובץ למערכת יש לבחור קובץ אן לגרור אותו לאזור הזה',
    Common: 'כללי',
    Company: 'חברה',
    'Confirm Password': 'אימות סיסמה חדשה',
    'Confirmation code': 'נא הזן קוד אימות',
    'Resend it': 'לשלוח שוב',
    "Didn't get the code": 'לא קיבלת קוד',
    'Code is invalid': 'קוד אימות לא נכון',
    Connections: 'חיבורים',
    Contact: 'פרטי קשר',
    'Contact email': 'כתובת מייל',
    'Invalid email address': 'כתובת דוא״ל לא תקין',
    'Contact name': 'שם בעל העסק',
    'Contact phone': 'מספר טלפון ליצירת קשר',
    Content: 'תוכן',
    Continue: 'המשך',
    'Change Template': 'לבחור תבנית אחרת',
    'Content Filling (Page Content Without Products.). Uploading products will be at a different stage.':
      'מילוי תכנים (תוכן עמודים ללא מוצרים.). העלאת מוצרים תהיה בשלב אחר.',
    Color: 'צבע של הכותרת',
    Country: 'מדינה',
    'Сhoose a different template': 'לבחירת תבנית אחרת',
    Create: 'ליצור',
    'Create at': 'ליצור',
    'Create folder': 'ליצור תיקיה',
    'Credit card information': 'פרטי כרטיס אשראי',
    'Create a store from this template': 'להמשיך עם התבנית הזאת',
    'Customize your home page': 'התאם אישית את דף הבית של החנות',
    Dasboard: 'לוח בקרה',
    Data: 'נתונים',
    'Data saved sucessfully!': 'נתונים נשמרו בהצלחה',
    'Uploaded date': 'תאריך הוספה',
    'Date of birth': 'תאריך לידה',
    delete: 'למחוק',
    Delete: 'מחיקה',
    Deleted: 'מחוק',
    Design: 'עיצוב',
    'Definition Free shipping terms (for example - free shipping on purchases over 300 NIS)':
      'הגדרת סכום קנייה שמעניק לקונה משלוח חינם',
    'Package dimensions': 'מידות אריזה למשלוח',
    'Discount price': 'מחיר  בהנחה',
    'Discounted date': 'הנחה מתאריך',
    Duration: 'משך',
    Domain: 'שם דומיין של החנות',
    'Each shipping method has a status Active - The method is available to the customer Inactive - Method not available to customer':
      'לכל שיטת משלוח ישנו סטטוס: פעיל - השיטה זמינה ללקוח לא פעיל - שיטה לא זמינה ללקוח',
    Edit: 'לערוך',
    'Edit brand': 'עריחת מותג',
    'Edit category': 'עריכת קטגוריה',
    'Edit option': 'עריכת מאפיין',
    'Edit characteristic': 'עריכת תכונה',
    'Edit shipment': 'עריכת משלוח',
    'Edit shipment__1': 'עריכת משלוח',
    'Edit testimonial': 'עריכת המלצה',
    Email: 'כתובת דוא״ל',
    email: 'כתובת דוא״ל',
    English: 'אנגלית',
    'Enter folder name': 'הלהזין שם תיקיה',
    'Enabled block "Benefits"': 'הפעלת בלוק מידע ״יתרונות״',
    'Enabled block "About"': 'העפלת בלוק מידע ״אודות החנות״',
    Error: 'שגיאה',
    Example: 'דוגמא',
    'Explanations about the shipping method that will appear in a user':
      'הסברים לגבי שיטת משלוח אשר יופיעו אצל משתמש',
    'File deleted successfully': 'קובץ נמחק בהצלחה',
    Files: 'קבצים',
    "Filling in the seller's details for the purpose of creating the seller's wallet system and receiving payment for products / services sold through the store":
      'בחירת צבעים עבור התבנית.',
    'Folder created successful': 'תיקיה נוצרה בהצלחה',
    'Forgot pasword': 'שכחתי סיסמה',
    Free: 'חינם',
    'Free domain': 'שם דומיין אוטומטי',
    'Free from': '-חינם מ',
    'Free from (example)':
      'הגדרות נוספות לשיטה (לדוגמא - משלוחמ ללא עלות בקנייה מעל 300 ₪)',

    'Full Description': 'תיאור מלא',
    'H1 tag': 'תאג  H1',
    Hebrew: 'עברית',
    'Height (sm)': 'גובהה  ס״מ',
    'Here you are asked to register the name of your store. This name appears in the title of the store page. It will be possible to change the name of the store at any stage of working with the store.':
      'נא להזין את שם החנות שיופיע באתר החנות',
    'Here you will apply to choose the design template of your store. Later you will be able to edit the template you selected':
      'כאן תוכלו לבחור את תבנית עיצובית של החנות שלכם. בהמשך יהיה ניתן לערוך את התבנית שבחרתם',
    'Here you can define options for receiving a product by a customer - delivery, self-collection, etc.':
      'כאן אפשר להוסיף שיטות משלוח אפשר לרשום כמה שיטות משלוח, בעלות מחיר שונה וכו׳.',
    "Here you can enter the address of a web page that a user goes through after clicking the button. To avoid address errors, we recommend opening the desired Web page in another browser tab and copying the address from the browser's address bar.":
      'כאן ניתן לרשום כתובת של עמוד אינטרנט שעליו עובר משתמש לאחר לחיצה על הכפתור. על מנת למנוע שגיאות בכתובת, אנו ממליצים לפתוח את העמוד אינטרנט הרצוי , בכרטיסייה אחרת של הדפדפן והעתק את הכתובת משורת הכתובת של הדפדפן.',
    History: 'היסטוריה',
    Homepage: 'עמוד הבית',
    House: 'בית',
    'If you have a domain or subdomain, you can attach it to your store. Visit Instructions page if you have any questions':
      'אם יש ברשותכם את שם דומיין, אפשר לחבר אותו כאן',
    Image: 'תמונה',
    Images: 'תמונות',
    Information: 'מידע',
    'Infographic sections. If you have trouble finding the matching icons, contact us for help.':
      'קטעי אינפוגרפיקה. אם אתם מתקשים במציאת אייקונים המתאמים, פנה אלינו לעזרה.',
    'In stock': 'במלאי',
    'In this section you can enter text that tells about the benefits of the products you sell or the benefits of buying in your store. By clicking on the "What does it look like on the site" button. You can see where text and images will appear which you will enter in this section. If you do not operate this part, it will not appear in the template of the store':
      'בחלק הזה ניתן להזין טקסט שמספר על יתרונות של המוצרים שאתם מוכרים או יתרונות קניה בחנות שלכם.  בלחיצה על כפתור ״איך זה נרא באתר״ תוכלו לראות איפה יופיעו טקסט ותמונות אשר תזינו בחלק הזה. במידה ולא תפעלו את חלק הזה הוא לא יופיע בתבנית של החנות',
    'In this section you can enter text and photos about the store. By clicking on the `What does it look like on the site` button. You can see where text and images will appear which you will enter in this section. If you do not operate this part, it will not appear in the template of the store':
      'בחלק הזה ניתן להזין טקסט ותמונות אודות החנות.  בלחיצה על כפתור ״איך זה נרא באתר״ תוכלו לראות איפה יופיעו טקסט ותמונות אשר תזינו בחלק הזה. במידה ולא תפעלו את חלק הזה הוא לא יופיע בתבנית של החנות',
    'input search text': 'להזין טקסט לחיפוש',
    'If you do not currently have the logo, you can add it later at each stage of working with the store.':
      'במידה ואין לכם כרגע את הלוגו, תוכלו להוסיף אותו אחר כך בכל שלב עבודה עם החנות.',
    'If you do not add banners, this section will not be displayed in the store template.':
      'בלוק מידע ״אודות החנות״ מאפשר לכם לפרסם באתר את סיפור החנות שלכם. בלוק כולל כותרת, טקסט תיאור ותמונה להמחשה, כפי שמופיע בדוגמא.',
    'Internet domaun name for your shop':
      'במידה ועדיין אין לכם דומיין לחנות, אפשר לרכוש אותו ברך מערכת הזאת ע״י לחיצה על כפתור... במידה ולא רוצים לרכוש את שם דומיין בשלב הנוכחי ישנה אפשרות לקבל שם דומיין אוטומטי ולהחליף אותו בשלב מאוחר יותר',
    'It\'s an extension of the store\'s address. For example, if you sell shoes and want to define a product page extension "high heels", just add the words - high heels to this field and then the page address will look like this - www.mystore.com/shoes':
      'זה סיומת של כתובת של החנות. לדוגמה אם אתם מוכרים נעליים ורוצים להגדיר סיומת של עמוד מוצר ״נעלי עקב״, פשוט תוסיפו את המילים  - נעלי עקב לשדה הזה ואז כתובת של העמוד יראה ככה - www.mystore.com/נעלי_עקב',
    'Length (sm)': 'אורך ס״מ',
    'Length of title up to 45 characters': 'אורך של הכותרת עד 45 תווים',
    'Length of text up to 100 characters': 'אורך של הטקסט עד 100 תווים',
    Login: 'להתחבר',
    Logout: 'ניתוק',
    'Member gender': 'מין',
    'Member ID': 'תז',
    'Member ID date': 'תאריך הנפקת ת.ז.',
    'Member name': 'שם מורשה חתימה',
    'Member signatory': 'מורשה חתימה',
    Method: 'שיטה',
    Moderation: 'מודרציה',
    'New password': 'סיסמה חדשה',
    Next: 'הבא',
    No: 'לא פעיל',
    'Not Active': 'לא פעיל',
    'Not active': 'לא פעיל',
    'Notify text': 'טקסט של הודעה',
    'Notify user about status change': 'להודיע למשתמש על שינוי סטטוס',
    Number: 'מספר',
    Opacity: 'שקיפות הכהה',
    Option: 'מאפיין',
    Options: 'מאפיינים',
    'Options & Variants': 'אופציות / וריאנטים',
    Order: 'הזמנה',
    'Order details': 'פרטי הזמנה',
    'Order status': 'סטטוס ההזמנה',
    'Order updated successfull': 'ההזמנה עודכנה בהצלחה',
    'Order was not paid': 'ההזמנה לא שולמה',
    Orders: 'הזמנות',
    'Overlay color': 'צבע הכהה של תמונת רקע',
    'Own domain': 'שם דומיין ייחודי',
    'Please create a password': 'נא ליצור סיסמה',
    'Pay attention! Logo does not appear on all templates. If in the template you have chosen a logo is not placed you can skip this step':
      'שימו לב! לוגו לא מופיע בכל התבניות. אם בתבנית שבחרתם לוגו לא ממוקם אתם יכולים לדלג על השלב הזה',
    'Pay attention! All products or brands appear in the template for illustration only!':
      'שימו לב!  ​​כל המוצרים או מותגים מופעים בתבנית לצורך המחשה בלבד!',
    'Pay attention!!! Use of this information block is not mandatory. If you do not activate the block it will simply not appear on the site.':
      'שימו לב!!! שימוש בבלוק מידע הזה לא אינו חובה. אם לא תפעילו את הבלוק הוא פשוט לא יופיע באתר.',
    Password: 'סיסמה',
    'Password confirmation': 'אימות סיסמה',
    'Password must contain at least 8 characters, at least 1 number, one lowercase letter and at least 1 uppercase letter':
      'הסיסמה חייבת לכללול לפחות 8 תוים, לפחות  ספרה אחת, אות  אחת גדולה, ולפחות אות אחת קטנה.',
    'Payment details': 'פרטי תשלום',
    'Payment in progress': 'תהליך ביצוע תשלום',
    Payments: 'תשלומים',
    Phone: 'מספר טלפון',
    Phones: 'מה׳ טלפון',
    Plan: 'תעריף הנוכחי',
    'Plans and pricing': 'תעריפים ותוכניות',
    'Please confirm your password!': 'נא לאמת את הסיסמה',
    'Please fill out your email. A verification email will be sent there.':
      'אנא מלא את האימייל שלך. קוד אימות יישלח לשם',
    Preview: 'תצוגה מקדימה',
    Price: 'מחיר',
    Product: 'מוצר',
    "Product delivery time to customer in this method. (For example - delivery to the customer's home up to 5 business days)":
      'זמן אספקת מוצר ללקוח בשיטה הזאת. (לדוגמה - אספקה עד בית הלקוח עד 5 ימי עסקים)',
    'Product variants': 'וריאנטים של המוצר',
    Products: 'מוצרים',
    'Products quantity': 'כמות מוצרים',
    Promotion: 'קידום',
    'Promotion page image': 'תמונת הקטגוריה להצגה בעמוד הראשי',
    Properties: 'פרטים',
    Characteristic: 'תכונה',
    Characteristics: 'תכונות',
    'Property title': 'שם של תכונת המוצר',
    Quantity: 'כמות',
    Radio: 'כפתור בחירה',
    'Regiter date': 'תאריך הרשמה',
    'Replayed to': 'השיב ל',
    Reset: 'איפוס',
    'Reset password': 'איפוס סיסמה',
    Reviews: 'ביקורות',
    Role: 'תפקיד',
    Russian: 'רוסית',
    'Sale from': 'פורסם מתאריך',
    'Sales Price': 'מחיר',
    Save: 'לשמור',
    'Save & Exit': 'לשמור ולצאת',
    'Save & Continue': 'לשמור ולהמשיך',
    Schema: 'פלטת צבעים',
    Search: 'חיפוש',
    'See how it looks on the site': 'איך זה נרא באתר',
    Select: 'לבחור',
    'Select image': 'לבחור תמונה',
    Selected: 'נבחר',
    'Select brand...': 'לבחור מותג',
    'Select Plan': 'בחר תוכנית',
    'Select Shop...': 'לבחור חנות',
    'Select theme color': 'נא לבחור פלטת צבעים לתבנית',
    Seller: 'פרטי המוכר',
    'Seller information': 'מילוי פרטי המוכר (החברה)',
    Send: 'שלח',
    'Send verification code': 'נא לשלוח קוד אימות',
    'SEO description': 'תיור SEO',
    'SEO Description': 'תיור SEO',
    Settings: 'הגדרות',
    Setup: 'תכנים',
    Shipment: 'משלוח',
    'Shipment details': 'פרטי משלוח',
    'Shipping Description': 'תיאור מלא של שיטת משלוח',
    'Shipping Duration': 'תיאור קצר של שיטת משלוח',
    'Shipping methods': 'שיטת משלוח',
    'Shipping Price': 'מחיר משלוח',
    'Shipping Status': 'סטטוס של השיטה',
    'Shipping Title': 'כותרת לשיטת משלוח',
    'Shipping Tooltip': 'טיפים לשיטת משלוח',
    Shop: 'חנות',
    'Shop Admin': 'מנהל מערכת',
    'Shop domain name': 'שם דומיין של החנות',
    'Shop Languages': 'שפה',
    'Shop legal address': 'כתובת החנות',
    'Shop legal details': 'פרטי חנות (לפי מה שרשום לתעודת עסק)',
    'Shop legal number': 'מספר ח.פ. / ע.מ',
    'Shop legal title': 'שם החנות (לפי תעודת עסק)',
    'Shop logo': 'לוגו של החנות',
    'Shop Manager': 'מנהל חנות',
    'Shop title': 'שם החנות',
    Shops: 'חנויות',
    'Short Description': 'תיאור קצר',
    'Show on Homepage': 'להציג בעמוד הראשי',
    'Showing {{start}} - {{end}} of {{total}} records':
      'מוצגים {{start}} - {{end}} מתוך {{total}} רשומות',
    'Sign Up': 'הרשמה',
    Size: 'גודל הקובץ',
    Size__1: 'מידה',
    SKU: 'מק״ט',
    Slug: 'סיומת כתובת של החנות.',
    Sort: 'מיון',
    Status: 'סטטוס',
    Street: 'רחוב',
    'Store logo': 'שינוי תמונת לוגו של החנות',
    'Store name': 'שם החנות',
    'Subtract in warehouse': 'להפיל חישוב יתרת מלאי',
    Success: 'הצלחה',
    'Super Admin': 'מנהל ראשי',
    'This is a free address that our system generates.':
      'להלן שם דומיין שנוצר אוטומטי ע״י המערכת. לא ניתן לשנות את השם הזה',
    Template: 'תבנית',
    'Template Settings': 'הגדרות עיצוב של החנות',
    Templates: 'תבניות',
    Testimonials: 'המלצות',
    text: 'טקסט',
    Text: 'טקסט',
    'Text about the store': 'טקסט אודות החנות',
    'Text on the button': 'טקסט על הכפתור',
    'The two passwords that you entered do not match!':
      'שתי הסיסמאות שהזנת אינן תואמות!',
    'The URL you go through at the click of a button':
      'כתובת URL עליו עוברים בלחיצה על הכפתור',
    'Three steps to creating a store -': '- שלושה צעדים ליצירת חנות',
    title: 'כותרת',
    Title: 'כותרת',
    Tooltip: 'טיפים',
    Total: 'סה״כ',
    'To the previous template': 'לתבנית קודם',
    'To the next template': 'לתבנית הבא',
    Type: 'סוּג',
    Update: 'עידכון',
    'Upload files': 'להעלות',
    'Upload image': 'להעלות תמונה (אייקון)',
    'User Name': 'יצירת שם משתמש',
    'User name': 'שם משתמש',
    Username: 'שם משתמש',
    Users: 'משתמשים',
    User: 'משתמש',
    URL: 'כתובת URL',
    'Verification code sent you to your email address. Put it at corresponding input.':
      'קוד אימות נשלח לכתובת מייל שציינת בהרשמה',
    View: 'תצוגה מקדימה',
    'View on site': 'צפייה באתר',
    'Waiting...': 'נא להמתין...',
    'Weight (kg)': 'משקל ק״ג',
    'Whant to join us? Sign up now!': 'רוצים להצטרף? תרשמו עכשיו',
    'When you change the name of the store you can see the change on the main page of the store (on the left) in real time.':
      'בעת שינוי שם החנות אתם יכולים לראות את השינוי בדף ראשי של החנות (בצד שמאל) בזמן אמת.',
    'Width (sm)': 'רוחב  ס״מ',
    'With button': 'להוסיף כפתור',
    Yes: 'אישור',
    'You have selected a template': 'בחרתם בתבנית',
    'You can add some phone numbers. The first number will appear at the top of the page and the rest of the numbers will appear at the bottom of the page':
      'אפשר להוסיף כמה מספרי טלפון. מספר הראשון יופיע בחלק העליון של הדף ושאר המספרים יופיעו בחלק התחתון של הדף',
    "You don't have any added banner ads yet.":
      'שימו לב!!! שימוש בבלוק מידע הזה לא אינו חובה.',
    'You can skip this step and come back to it later.':
      'אם לא תפעילו את הבלוק הוא פשוט לא יופיע באתר.',
    'You can add a background image to the banner. Optimal image size is 1920x760.':
      'באפשרותכם להוסיף תמונת רקע לבאנר.  גודל תמונה אופטימלי הוא 1920x760.',
    'Color change of title and text on the banner':
      'שינוי צבעים של כותרת וטקסט על הבנר',
    'You can create several banners. If you have created several banners, they will appear one after the other automatically (carousel). To create another banner, click on the "+ Update Banner" button. All the banners you have created will appear below the button above. Upon reaching this stage for the first time, at least one banner must be created. If you have difficulty creating a suitable banner for your store, contact us for help.':
      'באפשרותכם ליצור כמה באנרים. במידה ויצרתם כמה באנרים, הם יופיעו אחד אחרי השני באופן אוטומטי (קרוסלה).  לצורך יצירת באנר נוסף יש ללחוץ על הכפתור ״+ לעדכן באנר״. כל הבאנרים שיצרתם יופיעו מתחת לכפתור הנ״ל. עם הגעתם לשלב הזה בפעם הראשונה, יש ליצור לפחות באנר אחד. במידה ואתם מתקשים ליצור באנר הולם לחנות שלכם, פנה אלינו לעזרה.',
    'You can choose a free domain name created by the system or connect a unique domain name that you have purchased. If you need help purchasing a unique domain name, please contact us via the contact button.':
      'באפשרותכם לבחור שם דומיין חינמי אשר נוצר ע״י המערכת או לחבר שם דומיין ייחודי שרכשתם. אם אתם צריכים עזרה ברכישת שם דומיין ייחודי, נא לפנות עלינו דרך כפתור יצירת קשר.',
    'You do not have more than one Brand, please add a new one.':
      'שימו לב! עדיין לא הוספת אף מותג לרשימה.',
    "You don't have more than one Category, please add a new one.":
      'שימו לב! עדיין לא הוספת אף קתגוריה לרשימה.',
    'You do not have more than one Property, please add a new one.':
      'שימו לב! עדיין לא הוספת אף תכונת המוצר לרשימה.',
    'You do not have more than one Option, please add a new one.':
      'שימו לב! עדיין לא הוספת אף אופציה  למוצר ברשימה.',
    'You do not have more than one Image Product, please add a new one.':
      'שימו לב! עדיין לא הוספת אף תמונת המוצר.',

    //   Name Templates
    Cosmetics: 'קוסמטיקה',
    Market: 'מזון',
    Jewelry: 'צורפות',
    Clothes: 'ביגוד והנעלה',
    Food: 'אוכל מהיר',
    Furniture: 'ריהוט',
    Toys: 'צעצועים',
    'Food (Dark)': 'אוכל מהיר (כהה)',
    'Wine (Dark)': 'משקאות (כהה)',
    Electronics: 'אלקטרוניקה ומוצרי חשמל',
    PartyStore: 'ציוד לאירועים',
    Book: 'ספר',
    Bakery: 'מאפיה',
    'Chocolate (Dark)': '(כהה) שוקולד',

    'At this heart all the elements on the template are for illustration purposes only.':
      'בשלב הזה כל האלמנטים על התבמית הם לצורך המחשה בלבד',
    'Color change of title and text on the banne':
      'שינוי צבעים של כותרת וטקסט על הבנר',
    'In order to highlight the title and text on the banner you can darken the background image.':
      'על מנת להבליט את הכותרת ואת הטקסט על הבאנר באפשרותכם להכהות את תמונת רקע.',

    'Here will appear data about the operation of your shop. But it comes after filling the store with goods, content and the start of sales':
      'כאן יופיעו נתונים על תפעול החנות שלך. אבל זה יקרה לאחר מילוי החנות בסחורה, תוכן ותחילת המכירות',
    'Congrats! Your shop "{{name}}" is on the air.':
      'מזל טוב! החנות שלך  ״{{name}}״ באוויר',
    'Check my store': 'לראות את החנות',
    Prefix: 'קידומת',
    Postfix: 'פוסטפיקס',
    'Option title': 'שם של מאפיין',
    'Variants Images': 'תמונות של הווריאנט',
    'File size': 'גודל הקובץ',
    'Production date': 'תאריך יצירה',
    'No Data': 'אין נתונים',
    'Select template': 'לבחור תבנית',
    'Products management': 'ניהול מוצרים',
    'Add characteristic from existing': 'להוסיף תכונה מרשימת תכונות הקיימות',
    Value: 'ערך',
    'Product characteristics': 'תכונת המוצר',
    'Add option from existing': 'לבחור מאפיין מרשימה',
    'Add variant from existing': 'להוסיוף  וריאנט מרשית  וריאנטים הקיימים',
    'Editional images for color variants':
      'תמונות נוספות לוריאנטים של צבע המוצר',
    'Search products': 'חיפוש מוצרים',
    'Product title': 'שם המוצר',
    'All products': 'לכל המוצרים',
    'Edit product': 'עריכת מוצר',
    'Product price': 'מחיר המוצר',
    'Avalible for sale from': 'פורסם למכירה בתאריך',
    'Price with discount': 'מחיר עם הנחה',
    'Available date with discount': 'תאריך תחילת מכירה בהנחה',
    'Categories & Brands': 'קטגוריות ומותגים',
    'Edit product categories': 'עריכת קטגוריות מוצרים',
    'Products quantity in stock': 'כמות מוצרים במלאי',
    'Advansed SEO': 'הגדרות SEO מתקדמות',
    'Stock / Dimensions': 'ניהול מלאי /  מידות  אריזה',

    'Price variant': 'מחיר לוריאנט',
    'Discount variant price': 'מחיר הנחה לוריאנט',
    'Discount starts from': 'תאריך תחילת הנחה',
    'Product Variants': 'גרסות המוצר',
    'Add new variant': 'הוספת גרסת המוצר',
    'Product options': 'מאפייני הגרסה',
    'Additional images for this color': 'תמונות של המוצר בצבעים שונים',
    'Additional images': 'האם יש תמונות למאפיין כן /  לא',

    'Dashboard main text':
      '    היי אני עוזר וירטואלי שלכם. אני אעזור לכם לנהל ולקדם את החנות החדשה שלכם. החנות כבר קיימת ואפשר להעלות מוצרים. אפשר לעשות את זה בשני דרכים: להעלות מוצרים באופן מרוכז דרך קובץ אקסל או להוסיף מוצרים אחד אחרי השני. בכל מקרה אתם צריכים להתחיל העלאת ע״י לחיצה על הכפתור ״הוספת מוצרים״ למטה.<br/><br/>את המוצרים שכבר הוספתם לחנות אתם תמיד תוכלו לראות הלחיצה על הכפתור ״לראות את החנות״במידה ואחרי הוספת מוצרים אתם תרצו לעדכן את העיצוב של החנות, תמיד יהיה אפשר לעשות זאת דרך סעיפים בתפריט הימני.     ',

    'Products main text':
      'בעמוד הזה מופעים את כל המוצרים שכבר הועלו לחנות. כאן אפשר לעשות עריכה של המוצרים על ידי לחיצה על הכפתור עם עיפרון. כמו כן אפשר להוסיף מוצרים בודדים או באופן גורף ולחפש מוצרים ברשימה ע״י לחיצה על הכפתורים למעלה.',

    //Templates Categoryes
    Mens: 'גברים',
    Womans: 'נשים',
    Children: 'ילדים',
    Veggies: 'ירקות',
    Fruits: 'פרות',
    Dairy: 'דברי חלב',
    Pizza: 'פיצה',
    Burgers: 'המבורגרים',
    Desserts: 'קינוחים',
    Tables: 'שולחנות',
    Chairs: 'כיסאות',
    Beds: 'מיטןת',
    'Skin care': 'טיפוח העור',
    'Bath and body': 'אמבטיה וגוף',
    'Hair care': 'טיפול בשיער',
    Rings: 'טבעות',
    Chains: 'שרשראות',
    Diamonds: 'יהלומים',
    'Digital books': 'ספרים דיגיטליים',
    'Printed books': 'ספרים מודפסים',
    'Audio books': 'ספרים קוליים',
    'Board games': 'משחקי שולחן',
    Lego: 'לגו',
    Scooters: 'קורקינטים',
    'Refrigerators and freezers': 'מקררים ומקפיאים',
    'TVs and sound projectors': 'טלוויזיות ומקרני קול',
    'Baking ovens, stoves and cookers': 'תנורי אפייה כיריים וקולטים',
    Suitcases: 'מזוודות',
    Bags: 'תיקים',
    Wallets: 'ארנקים',
    'Brewed Rums': 'רום מבושל',
    'Finest Red Wines': 'היינות האדומים',
    Whiskeys: 'ויסקי',
    Breads: 'לחמים',
    Pies: 'עוגות',
    Pastries: 'מאפים',
    'Vegan chocolate': 'שוקולד טבעוני',
    Truffles: 'טראפלס',
    'Sweet hospitality trays': 'מגשי אירוח מתוקים',

    'Edit product text 1':
      'תיאור של כל שדה ואופן מילוי אתם יכולים לראות בלחיצה על סימן שאלה ליד כל שדה',
    'Product title bot message':
      '<b>כותרת (שם המוצר)</b> - שם המוצר המופיע ברשימת מוצרים ובכרטיס מוצר באתר החנות. אורך של שם המוצר יכול להיות עד 250 תווים. נא לשים לב - אם שם המוצר יהיה ארוך, הוא יופיע בכמה שורות.',

    'Product slug bot message':
      '<b>סיומת כתובת של החנות</b> - כאן אתם יכולים לערוך את הסיומת של כתובת של כרטיס המוצר ב אתר החנות כמו בדוגמא למטה. זה יכול להיות מאוד שימושי לצורך קידום החנות במנועי החיפוש.[[image]]בדוגמא הזאת מופיע סיומת כתובת של המוצר בחנות קוסמטיקה. שימו לב! סיומת כתובת של המוצר יכול להיות באנגלית או בעברית.',

    'Product SKU bot message':
      '<b>מק״ט</b> - מספר קטלוגי של המוצר. השדה הזה לא חובה. יש להשתמש בשדה הזה רק אם אתם משתמשים בקטלוג פנימי של מוצרים. ',

    'Product status bot message':
      '<b>סטָטוּס</b> - למוצר יש כמה סטטוסים:<br/> <ul><li>פעיל - מוצר מופיע באתר החנות,</li><li>לא פעיל - מוצר נמצא במערכת אך לא מופיע באתר החנות. ניתן להשתמש בסטטוס הזה במקרים שהוספתם מוצר למערכת כך טרם סיימתם  לערוך פרטי המוצר.</li><li>מוצר מחוק - מוצר נמחק מהמערכת, לא מופיע בחנות ו ברשימת המוצרים. אבל תמיד ניתן למצוא את המוצר ברשימת מוצרים המחוקים ולהחזיר אותו לרשימת מוצרים ע״י שינוי סטטוס</li></ul>',

    'Product short description bot message':
      '<b>תיאור קצר</b> - ניתן להוסיף תיאור קצר של כל מוצר אשר מופיע בקרוסלות מוצרים בעמוד הבית של החנות ובעמודי קטגוריה. ניתן להוסיף תיאור קצר עד 255 תווים. ראה דוגמא [[image]]',

    'Product full description bot message':
      '<b>תיאור מלא</b> - תיאור מלא של המוצר מופיע בכרטיס המוצר. אין מגבלה ב כמות התווים שאפשר להכניס בשדה הזה.  כמו כן יש אפשרות לערוך טקסט של תיאור מלא על ידי פקדים מעל שדה להכנסת טקסט',

    'Product price + product publication date bot message':
      '<b>מחיר המוצר + תאריך פרסום המוצר</b> - כאן מעדכנים מחיר של המוצר כמו שהוא יופיע בחנות. <b>שימו לב!!! כדי שהמחיר יופיע אן ישתנה בחנות יש לעדכן תאריך פרסום המוצר (שדה ״פורסם למכירה בתאריך״). אם תאריך פרסום המוצר לא יעודכן המחיר  החדש לא יופיה באתר. </b>',

    'Price with discount + available date with discount bot message':
      '<b>מחיר עם הנחה + תאריך תחילת מכירה בהנחה</b> - במידה ואתם רוצים לעשות הנחה על המוצר יש להכניס מחיר הנחה בשדה הזה. מחיר הנחה יופיע ליד מחיר רגיל בדפי החנות אבל נחיר רגיל יופי חיוור ומפוספס. <b>שימו לב!!! בדומה למחיר רגיל? מחיר הנחה יופיע אך ורק אחרי עדכון שדה ״תאריך תחילת מכירה בהנחה״</b>',

    'Product variants bot message':
      'לכל מוצר אפשר להוסיף גרסאות כגון צבע גודל וכו׳, בעלי מק״ט ומחיר שונים.  כדי להבין היטב את אופן הוספה וניהול גרסאות למוצר נא לצפות בסרטון הסבר. [[video]]',

    'Edit product categories bot message':
      '<b>עריכת קטגוריות מוצרים</b> - באפשרותכם לשייך את המוצר לאחד או יותר קטגוריות. לצורך כך אתם יכולים לסמן קטגוריות רצויות והמוצר יופיע בחנות תחת קטגוריות האלה. הסבר מפורט על אופן ניהול קטגוריות אתם יכולים לראות בסרטון הדרכה - [[video]]<br/> <b>שמו לב!</b> ניתן לערוך קטגוריות ע״י בחירת סעיף ״קטגוריות״ בתפריט הימני.',

    'Product characteristics bot message':
      '<b>תכונת המוצר</b> -  בפלטפורמה InClick אתם יכולים לשתף הרבה מידע לגבי המוצר עם הלקוחות שלכם. אחד מהכלים להוסיף מידע על המוצר - למלא שדות ״תכונת המוצר״. המידע שתוסיפו, יופיע בכרטיס המוצר תחת תיאור מלא של המוצר. תיראו הסבר קצר איך להוסיף ולערוך תכונות המוצר בסרטון - [[video]] <br/> <b>שמו לב!</b> ניתן לערוך תכונות מוצרים ע״י בחירת סעיף ״תכונות״ בתפריט הימני.',

    'Product brand bot message':
      '<b>מותג</b> - ניתן לשייך מוצר למותג. לצורך כך ניתן לבחור מותג מרשימה או ליצור מותג חדש. לאחר בחירת מותג יש לשמור שינוים ע״י לחיצה על הכפתורים למעלה.<br/> <b>שמו לב!</b> ניתן לערוך מותגים ע״י בחירת סעיף ״מותגים״ בתפריט הימני.',

    'Subtract in warehouse bot message':
      '<b>הפעלת חישוב יתרות מלאים</b> - על ידי הפעלת פונקציה ״חישוב יתרות מלאים״ אתם תוכלו לדעת שמוצר מסוים יצא מהמלאי ומערכת תסיר את המוצר מדפי החנות באופן אוטומטי עד החידוש מלאים.',

    'Products quantity in stock bot message':
      '<b>כמות מוצרים במלאי</b> - כאן ניתן לעדכן כמות יחידות המוצר הנמצאים במלאי. כי ברירת מחדל כמות מוגדרת ל 1. במידה והפעלתם פונקציה  ״<b>חישוב יתרות מלאים</b>״ לאחר כל מכירה כמות מוצרים המוצג בשדה הזה תקטן בהתאם לכמות שנמכר.',

    'Package dimensions bot message':
      '<b>מידות אריזה למשלוח</b> - בשדות האלה אפשר להוסיף מידות האריזה של המוצר לצורך חישוב עלות המשלוח.',

    'Select a folder bot message':
      'כל התמונות שבהן יש שימוש בדפי החנות נשמרות במנהל הקבצים הפנימי לכן אני מציעה להקים תיקיית תמונות עבור כל מוצר ולשמור תמונות המוצר שם. כך יהיה יותר קל לנהל את אוסף תמונות של כל מוצר.',

    'SEO Description bot message':
      '<b>SEO</b> - כאן אפשר להוסיף הגדרות SEO לעמוד המוצר. אנחנו מצעים להתייעץ עם מומחה בקידום אתרים.  אם אתם לא מכירים איש מקצוע בתחום, תפנו בבקשה מחלקת תמיכה של פלטפורמה InClick ונוכל לסייע במציאת מומחה המתאים.',
    'Our Premium Collection': 'קולקציית הפרימיום שלנו',
    'Our products': 'מוצרים שלנו',
    'Our Menu': 'תפריט',
    'Our Collection': 'קולקציות',
    'Popular Categories': 'קטגוריות פופולריות',
    'Product returned': 'תהליך החזרת המוצר הושלם בהצלחה',
    Returns: 'החזרות',
    Returned: 'סכום שהוחזר ללקוח',
    'Are you shure you want to Cancel product and pay Money back?':
      'בלחיצת כפתור ״אישור״ אתם מסכמים לקבל את המוצר ולהחזיר כסף ללקוח.',
    'Return requested': 'בקשה להחזר מוצר',
    'Create return': 'החזר של המוצר',
    'Return for Order': 'בקשה להחזר מוצר בהזמנה',
    'Order return status': 'סטטוס של בקשה להחזר מוצר',
    'Order Number': 'מס׳ הזמנה',

    'Client cancelation': 'הזמנה בוטלה',
    // 'System aborted' : '',
    'Just created': 'הזמנה נוצרה ע״י תשלום טלפוני',
    'Product reservation': 'פריטים נשמרו',
    'Total paid': 'שולם',
    'Order preparation': 'הכנת הזמנה',
    'Waiting for sending': 'מוכן למשלוח',
    'Was send': 'שליח הוזמן',
    'Client recieved': 'הזמנה התקבלה',
    Date: 'תאריך',
    'Shop Address': 'כתובת החנות',
    'Shop Social Networks': 'קישורים לרשתות חברתיות',
    'Image size': 'גודל התמונה',
    'up to 1MB': 'לא יותר מ 1MB',
    Name: 'שם',
    'You can add a product image. Optimal image size is 1080x1440.':
      'באפשרותכם להוסיף תמונת המוצר. גודל האופטימלי לתמונות הוא 1080x1440.',

    Enabled: 'פעיל',
    Disabled: 'מושבת',
    Disable: 'מושבת',

    'Common settings': 'הגדרות כלליות',
    'Shop benefits': 'יתרונות החנות',
    'About shop': 'אודות החנות',
    'Change template': 'שינוי תבנית',
    'Terms & Privacy': 'תקנון ומדיניות  האתר',
    'Shop settings': 'הגדרות החנות',
    'Business management': 'ניהול העסק',

    'Uploadable documents': 'הוספת מסמכים',
    'File ID': 'ת.ז. של מורשה חתימה',
    'File Certificate': 'תעודת התגדות',
    'File Bank': 'אישור חשבון בנק',
    'Select a file': 'בחר קובץ',

    ' Add new Term or Privacy': 'הוספת תקנון חדש',
    'Option value': 'מחיר של אופציה',
    Description: 'תיאור',
    'Category description': 'תיאור של הקטגוריה',
    'Homepage text color': 'צבע טקסט בעמוד הראשי',
    'Shop contact Email address': 'דוא״ל איש קשר בחנות',
    'To properly configure the DNS server, follow these instructions.':
      'להגדרות נכונות של שרת  DNS יש לציית להוראות',
    'Detach domain': ' לנתק דומיי',
    'Add new terms': 'הוספת תקנון חדש',
    'Add new Term or Privacy': 'הוספת תקנון חדש',
    'Terms and Privacy': 'תקנון האתר',
    'Terms and conditions': 'תקנון האתר',
    'Privacy policy': 'מדניות פרטיות',
    'User details': 'פרטי הלקוח',
    'Payment holded': 'הזמנה אושרה',
    Variant: '',
    String: '',
    number: 'מספר',
    'Effectivity & Promotion': 'יעול וקידום',
    'Price per kg': 'מחיר ל ק״ג',
    'Price per KG': 'מחיר ל ק״ג',
    kg: 'ק״ג',
    gr: 'גרם',
    'per KG': 'לק״ג',
    'per gr': 'ל  גרם',
    'Export invoices to one PDF': 'ייצוא חשבוניות ל-PDF אחד',
    'Invoice link': 'קישור לחשבונית',
    'Select dates range to export Invoices': 'בחר טווח תאריכים לייצוא חשבוניות',
    Start: 'להתחיל',

    Welcome: 'היי',
    'please login': 'נא להתחבר',
    'A few minutes and you have an online store. If you have previously registered for the InClick system - ':
      'כמה דקות ויש לך חנות באינטרנט.  אם נרשמת בעבר למערכת InClick',
    'This link must be clicked in order for the system to send a new code.':
      'יש ללחוץ על הקישור הזה על מנת שמערכת תשלח קוד חדש.',
    "Didn't get a code?": 'לא קיבלת קוד?',
    'What is the name of your new store?': 'שתי שאלות לגבי החנות שברצונך ליצור',
    'Type of business': 'נא לבחור סוג העסק שעבורו יוצרים חנות',
    'Enter the name of your store':
      'נא להזין שם של החנות. שם של החנות יכול להיות בעברית או לועזית',
    'Store link':
      'להלן שם דומיין של החנות. תוכלו לשנות אותו אחרי סיום תהליך הקמה או לחבר את שם דומיין שכבר רכשתם.',
    'Create store': 'להתחיל תהליך הקמת חנות',
    'Login to the system': 'התחברות למערכת',
    'If you are not a registered user of the InCliCk system, please register in a simple and convenient process that only takes a few minutes.':
      'אם אינך משתמש רשום במערכת InCliCk, נא להירשם בתהליך פשוט ונוח שלוקח רק כמה דקות ספורות.',
    'Start registration process?': 'להתחיל תהליך הרשמה?',
    'Please enter a verification code sent by email':
      'נא להזין קוד אימות שנשלח במייל',
    'Back to previous step': 'חזרה לשלב קודם',
    'Two questions about the store you want to create':
      'שתי שאלות לגבי החנות שברצונך ליצור',
    'Send code': 'שלח קוד',
    'Validate code': 'אמת את הקוד',
    'Help & Support': 'תמיכה',
    'Quick Start guide': 'מדריך להתחלה מהירה',
    '{{tasks}} of {{count}} tasks completed':
      'צעד {{tasks}} מתוך {{count}} הושלמו',
    'Сhoose a store template': 'בחירת תבנית להקמת חנות',
    'Select and customize themes for the store':
      'לבחור להתאים אישית את תבנית ליצירת חנות',
    'Сhoose a template': 'קדימה לבחירת תבנית',
    Analytics: 'אנליטיקה עבודת חנות',
    'Coming soon': 'יפתח בקרוב',
    'Select this template': 'לבחור את התבנית הזו',
    'Home page': 'דף בית',
    'Catalog page': 'דף קטגוריה',
    'Product page': 'דף מוצר',
    'Connect another domain': 'לחבר דומיין אחר',
    'Attention! ​​All products or brands appear in template for illustration purposes only!':
      'שימו לב! תמונות מוצרים המופעים בתבנית הם לצורך המכשה בלבד',
    'Terms & Conditions': 'תנאי שימוש',
    'Returns & Warranty': 'אחריות והחזרות',
    'Change plan': 'עדכון תעריף שימוש במערכת',
    'Choosing a usage plan for the InClick system':
      'בחירת תעריף שימוש במערכת InClick',
    'Select plan': 'בחר תאריף',
    'Choosing a system usage route (tariff)':
      'בחירת מסלול שימוש במערכת (תעריף)',
    'Please choose a usage plan appropriate for the size of your store':
      'נא לבחור תעריף שימוש המתאים לגודל החנות שלכם',
    'Add seller data': 'הוספת מידע על החנות',
    'Add seller data and launch store': 'להוסיף מידע על החנות ולהשיק אותה',
    'Edit template': 'עדכון תבנית',
    'View your store': 'לדפי החנות',
    'Edit profile': 'הגדרות פרופיל',
    'Add category': 'הוספת קטגוריה',
    'Add product categories to the store': 'הוספת קטגוריות מוצרים בחנות',
    'Add products': 'למלא מוצרים',
    'Setting up shipping options': 'הגדרת אפשרויות משלוח',
    'Add your first product to the store':
      'להעלות את מוצר הראשון לחנות החדשה שלך',
    'Add shipping options for your business needs':
      'לבחור באופציות משלוחים המתאמים לעסק שלך',
    Visitors: 'מבקרים',
    Byuers: 'קונים',
    Profit: 'רווח',
    'Store statistics for the last 30 days':
      'סטטיקה  פעילות החנות ב 30 ימים האחרונים',
    'Member phone': 'טלפון המשתתף',

    'User Groups': 'קבוצת משתמשים',
    'Select group': 'בחר קבוצת משתמשים',
    Discount: 'הנחה',
    'Edit User Group': 'עדכון קבוצת משתמשים',
    'Order comments': 'הערות להזמנה',
    'Add new user': 'הוסף משתמש חדש',
    'New user': 'משתמש חדש',
    'First name': 'שם פרטי',
    'Last name': 'שם משפחה',
    'Edit user': 'ערוך משתמש',
    Installments: 'תשלומים',
    'Show history': 'הצג היסטוריה של ההזמנה',
    'Payed with bonuses': 'שולם עם בונוסים',
    'Will be payed with bonuses': 'לשלם עם בונוסים',
    'Discount for this product': 'הנחה על המוצר זה',

    Agent: 'סוכן',
    'Agent number': 'מספר סוכן',
    'With cashless': 'תשלום טלפוני',
    'Cashless payments available': 'אפשרות תשלום טלפוני הופעל',

    Shipments: 'אופציות משלוח',
    Administration: 'ניהול מערכת',
    Group: 'קבוצה',
  },
};

export default he;
