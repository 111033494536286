import {useContext, useEffect, useState} from 'react';
import CommonStore from './store/CommonStore';
import 'antd/dist/antd.less';
import './less/app.less';
import {Observer, Provider} from 'mobx-react';
import {ConfigProvider, Spin} from 'antd';
import {BrowserRouter} from 'react-router-dom';
import {Admin, AdminRoles} from './types';
import api from './api/api';
import MainLayout from './components/MainLayout';
import GuestRouter from './router/GuestRouter';

import en from 'antd/lib/locale/en_GB';
import ru from 'antd/lib/locale/ru_RU';
import he from 'antd/lib/locale/he_IL';
import 'moment/locale/ru';
import 'moment/locale/he';
import {useTranslation} from 'react-i18next';
import {Locale} from 'antd/lib/locale-provider';

export const allLangs: {
  [key: string]: {locale: Locale; direction: 'ltr' | 'rtl'};
} = {
  en: {locale: en, direction: 'ltr'},
  ru: {locale: ru, direction: 'ltr'},
  he: {locale: he, direction: 'rtl'},
};

const App = () => {
  //
  const storeCommon = useContext(CommonStore);

  //
  const {i18n} = useTranslation();

  //
  const [loaded, setLoaded] = useState(false);

  //
  useEffect(() => {
    window.addEventListener('storage', e => {
      if (!localStorage.getItem('token')) {
        window.location.href = '/login';
      }
    });

    (async () => {
      if (localStorage.getItem('token')) {
        const resp: Admin = await api(
          'admins/me?expand=shop,shop.settings.template',
        );
        if (resp?._id) {
          // if (
          //   resp.shop &&
          //   resp.role !== AdminRoles.SUPER_ADMIN &&
          //   !resp.shop?.settings?.languages
          // ) {
          //   resp.shop.settings.languages = ["he"];
          // }
          storeCommon.setUser(resp);
        }
      }
      setLoaded(true);
    })();
  }, []);

  return (
    <Provider storeCommon={storeCommon}>
      <BrowserRouter>
        <ConfigProvider
          locale={allLangs[i18n.language]?.locale || en}
          direction={allLangs[i18n.language]?.direction || 'ltr'}>
          <Observer>
            {() => {
              if (loaded) {
                if (
                  storeCommon.user &&
                  (storeCommon.user.role === AdminRoles.SUPER_ADMIN ||
                    storeCommon.user?.shop)
                ) {
                  return <MainLayout />;
                }
                return <GuestRouter />;
              }

              return (
                <div
                  style={{
                    minHeight: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Spin size='large' />
                </div>
              );
            }}
          </Observer>
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
