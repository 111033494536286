import {Result} from 'antd';
import React, {lazy, Suspense, useContext} from 'react';
import {Switch, Route, useHistory} from 'react-router-dom';
import CommonStore from '../store/CommonStore';
import {AdminRoles} from '../types';

const Dashboard = lazy(() => import('../pages/Dashboard'));

const Shops = lazy(() => import('../pages/Shops'));
const EditShop = lazy(() => import('../pages/Shops/EditShop'));

// const Settings = lazy(() => import("../pages/MainSettings"));
const EditSetting = lazy(() => import('../pages/Settings/EditSetting'));

const Products = lazy(() => import('../pages/Products'));
const EditProduct = lazy(() => import('../pages/Products/EditProduct'));

const Categories = lazy(() => import('../pages/Categories'));

const Brands = lazy(() => import('../pages/Brands'));

const Branches = lazy(() => import('../pages/Branches'));

const Banners = lazy(() => import('../pages/Banners'));

const Orders = lazy(() => import('../pages/Orders'));
const OrderForm = lazy(() => import('../pages/Orders/OrderForm'));

const Returns = lazy(() => import('../pages/Returns'));
const ReturnForm = lazy(() => import('../pages/Returns/ReturnForm'));

const Reviews = lazy(() => import('../pages/Reviews'));

const Options = lazy(() => import('../pages/Options'));

const Properties = lazy(() => import('../pages/Properties'));

const Testimonials = lazy(() => import('../pages/Testimonials'));
const Shipments = lazy(() => import('../pages/Shipments'));

const AdminTemplates = lazy(() => import('../pages/Templates'));
// const Templates = lazy(() => import("../pages/Dashboard/Step1/Screen1"));

const Admins = lazy(() => import('../pages/Admins'));
const EditAdmin = lazy(() => import('../pages/Admins/EditAdmin'));

const Users = lazy(() => import('../pages/Users'));
const UserGroups = lazy(() => import('../pages/UserGroups'));
const EditUser = lazy(() => import('../pages/Users/EditUser'));

const Homepage = lazy(() => import('../pages/Homepage'));

const Terms = lazy(() => import('../pages/Terms'));
const EditTerm = lazy(() => import('../pages/Terms/EditTerm'));

const Seostrider = lazy(() => import('../pages/Seostrider'));

const SelectTemplate = lazy(() => import('../pages/SelectTemplate'));
const MainSettings = lazy(() => import('../pages/MainSettings'));
const Benefits = lazy(() => import('../pages/Benefits'));
const About = lazy(() => import('../pages/About'));
const SelectPlan = lazy(() => import('../pages/SelectPlan'));
const SellerData = lazy(() => import('../pages/SellerData'));

const AppRouter = () => {
  //
  const storeCommon = useContext(CommonStore);

  //
  const history = useHistory();

  return (
    <Suspense fallback={null}>
      <Switch>
        <Route path='/' exact render={() => <Dashboard />} />
        {storeCommon.user?.role === AdminRoles.SUPER_ADMIN && (
          <Route path='/shops' exact render={() => <Shops />} />
        )}
        {storeCommon.user?.role === AdminRoles.SUPER_ADMIN && (
          <Route path='/shops/create' exact render={() => <EditShop />} />
        )}
        {storeCommon.user?.role === AdminRoles.SUPER_ADMIN && (
          <Route path='/shops/:shopId' render={() => <EditShop />} />
        )}
        {storeCommon.user?.role === AdminRoles.SHOP_ADMIN && (
          <Route
            path='/settings'
            exact
            render={() => (
              <MainSettings withBackButton={false} successCallback={() => {}} />
            )}
          />
        )}
        {storeCommon.user?.role === AdminRoles.SHOP_ADMIN && (
          <Route
            path='/templates'
            exact
            render={() => <SelectTemplate successCallback={() => {}} />}
          />
        )}
        {storeCommon.user?.role === AdminRoles.SHOP_ADMIN && (
          <Route
            path='/benefits'
            exact
            render={() => <Homepage step='benefits' />}
          />
        )}
        {storeCommon.user?.role === AdminRoles.SHOP_ADMIN && (
          <Route path='/about' exact render={() => <Homepage step='about' />} />
        )}
        <Route path='/products' exact render={() => <Products />} />
        <Route path='/products/create' exact render={() => <EditProduct />} />
        <Route path='/products/:productId' render={() => <EditProduct />} />
        <Route path='/categories' exact render={() => <Categories />} />
        <Route path='/brands' exact render={() => <Brands />} />
        <Route path='/branches' exact render={() => <Branches />} />
        <Route
          path='/banners'
          exact
          render={() => <Banners isFull successCallback={() => {}} />}
        />
        <Route path='/orders' exact render={() => <Orders />} />
        <Route path='/orders/:orderId' exact render={() => <OrderForm />} />
        <Route path='/returns' exact render={() => <Returns />} />
        <Route path='/returns/:returnId' exact render={() => <ReturnForm />} />
        <Route path='/reviews' exact render={() => <Reviews />} />
        <Route path='/options' exact render={() => <Options />} />
        <Route path='/properties' exact render={() => <Properties />} />
        <Route path='/testimonials' exact render={() => <Testimonials />} />
        <Route path='/shipments' exact render={() => <Shipments />} />
        <Route path='/seostrider' exact render={() => <Seostrider />} />
        {storeCommon.user?.role === AdminRoles.SUPER_ADMIN && (
          <Route path='/templates' exact render={() => <AdminTemplates />} />
        )}
        {storeCommon.user?.role !== AdminRoles.SHOP_MANAGER && (
          <Route path='/admins' exact render={() => <Admins />} />
        )}
        {storeCommon.user?.role !== AdminRoles.SHOP_MANAGER && (
          <Route path='/admins/create' exact render={() => <EditAdmin />} />
        )}
        {storeCommon.user?.role !== AdminRoles.SHOP_MANAGER && (
          <Route path='/admins/:adminId' render={() => <EditAdmin />} />
        )}
        <Route path='/users' exact render={() => <Users />} />
        <Route path='/users/:userId' render={() => <EditUser />} />
        <Route path='/user-groups' render={() => <UserGroups />} />
        {/* {storeCommon.user?.role === AdminRoles.SUPER_ADMIN && (
          <Route path="/settings" exact render={() => <Settings withBackButton={false} />} />
        )} */}
        {storeCommon.user?.role === AdminRoles.SUPER_ADMIN && (
          <Route path='/settings/create' exact render={() => <EditSetting />} />
        )}
        {storeCommon.user?.role === AdminRoles.SUPER_ADMIN && (
          <Route path='/settings/:settingId' render={() => <EditSetting />} />
        )}
        <Route path='/terms' exact render={() => <Terms />} />
        <Route path='/terms/create' exact render={() => <EditTerm />} />
        <Route path='/terms/:termId' render={() => <EditTerm />} />
        {/***********************
         *
         *
         *
         *
         *
         */}
        <Route
          path='/quick/templates'
          exact
          render={() => (
            <SelectTemplate
              successCallback={() => history.push('/quick/settings')}
            />
          )}
        />
        <Route
          path='/quick/settings'
          exact
          render={() => (
            <MainSettings
              withBackButton
              successCallback={() => history.push('/quick/banners')}
            />
          )}
        />
        <Route
          path='/quick/banners'
          exact
          render={() => (
            <Banners
              isFull={false}
              successCallback={() => history.push('/quick/benefits')}
            />
          )}
        />
        <Route
          path='/quick/benefits'
          exact
          render={() => (
            <Benefits
              isFull={false}
              successCallback={() => history.push('/quick/about')}
            />
          )}
        />
        <Route
          path='/quick/about'
          exact
          render={() => (
            <About isFull={false} successCallback={() => history.push('/')} />
          )}
        />
        <Route
          path='/quick/plan'
          exact
          render={() => (
            <SelectPlan successCallback={() => history.push('/')} />
          )}
        />
        <Route
          path='/quick/seller'
          exact
          render={() => (
            <SellerData successCallback={() => history.push('/')} />
          )}
        />
        {/***********************
         *
         *
         *
         *
         *
         */}
        <Route render={() => <Result status='404' title='404' />} />;
      </Switch>
    </Suspense>
  );
};

export default AppRouter;
